<template>
  <BaseLayout>
    <template #header>
      <h1>{{ title }}</h1>

      <section
        id="filter-section"
        class="p-3 rounded d-flex align-items-center justify-content-between"
      >
        <b-form-group
          class="mr-3 w-100"
          label="Masukkan Barcode"
          label-for="start-date"
        >
          <b-form-input
            id="barcode-input"
            v-model="formData.barcode"
            type="text"
            placeholder="Masukkan barcode"
            @input="checkingBarcodes"
          />
        </b-form-group>
      </section>
    </template>
    <template #content>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto overflow-scroll">
            <b-table
              class="table-borderless table-responsive w-100"
              :fields="fields"
              :busy.sync="isBusy"
              :items="items"
              show-empty
            >
              <template #empty>
                <div class="hp-p1-body text-center my-2">
                  <strong>Tidak Ada Data</strong>
                </div>
              </template>
              <template #table-busy>
                <div class="hp-p1-body text-center my-2">
                  <b-spinner class="align-middle mr-8" />
                  <strong>Memuat...</strong>
                </div>
              </template>
              <template #cell(action)="{ index }">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mr-1"
                  @click="removeItem(index)"
                >
                  <i class="ri-delete-bin-6-line" />
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mt-3 mb-3"
          :disabled="!items.length"
          @click="handleSubmit"
        >
          <b-spinner v-if="isLoading" small />
          Simpan
        </b-button>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import mapBloodType from "../../../../../utils/mapBloodType";
import manageBloodShipmentAPI from "../../../../../api/blood_shipment/manageBloodShipmentAPI";
import manageBloodActivitiesAPI from "../../../../../api/manageBloodActivites/manageBloodActivitiesAPI";
import { debounde } from "lodash";

export default {
  name: "AddBloodShipments",
  components: {
    BSpinner,
    BFormSelect,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BTable,
    BFormSelectOption,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      debounceCheckedBarcode: null,
      barcodesCollected: [],
      formData: {
        barcode: "",
      },
      options: [
        { value: "PENGOLAHAN", text: "Pengolahan" },
        { value: "KGD", text: "KGD" },
        { value: "IMLTD", text: "IMLTD" },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        {
          key: "barcode",
          label: "Barcode",
          sortable: false,
        },
        {
          key: "blood_bag_number",
          label: "Nomor Selang",
          sortable: true,
        },
        {
          key: "blood_type",
          label: "Golongan Darah",
        },
        {
          key: "bag_type",
          label: "Jenis Kantong",
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      items: [],
      counter: 1,
    };
  },
  created() {
    this.debounceCheckedBarcode = debounde(this.checkingBarcodes, 300);
  },
  props: {
    title: String,
    processActivity: String,
  },
  created() {
    this.debounceCheckedBarcode = debounce(this.checkingBarcodes, 300);
  },
  methods: {
    async checkingBarcodes() {
      this.isBusy = true;
      try {
        const response = await manageBloodShipmentAPI.checkingBarcode({
          barcode: this.formData.barcode,
        });
        const tableItems = {
          no: this.counter++,
          barcode: response.data.data.barcode,
          blood_bag_number: response.data.data.blood_bag_number,
          blood_type: mapBloodType(response.data.data.blood_type),
          bag_type: response.data.data.bag_type,
        };

        const existsBarcode = this.items.some(
          (item) => item.barcode === tableItems.barcode
        );

        if (!existsBarcode) {
          this.items.push(tableItems);
        } else {
          this.$bvToast.toast("Barcode sudah dikirim sebelumnya", {
            title: "Peringatan",
            variant: "warning",
            solid: true,
          });
        }

        this.items.forEach((item, index) => {
          item.no = index + 1;
        });
        this.barcodesCollected.push(tableItems.barcode);
        this.formData.barcode = "";
      } catch (error) {
        console.error("Error Checking Barcode : ", error);
      } finally {
        this.isBusy = false;
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.barcodesCollected.splice(index, 1);
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        await manageBloodActivitiesAPI[this.processActivity]({
          barcodes: this.barcodesCollected,
        });
        this.$bvToast.toast("Berhasil Menambahkan Data", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.items = [];
      } catch (error) {
        console.error("Error Add Blood Shipment : ", error);
        this.$bvToast.toast(
          error.response?.data?.message || "Terjadi kesalahan",
          {
            title: "Gagal Menambahkan Data",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 600px;
}

.overflow-scroll {
  overflow-y: auto;
}
</style>
