import API from "../services/services";

class ManageBloodShipmentAPI {
  addProcessSieving(payload) {
    return API.post("blood_processing/add_sieving", payload);
  }

  addProcessWeighing(payload) {
    return API.post("blood_processing/add_weighing", payload);
  }

  addProcessCentrifugation(payload) {
    return API.post("blood_processing/add_centrifugation", payload);
  }

  addProcessComponentSeparation(payload) {
    return API.post("blood_processing/add_separation", payload);
  }
}

export default new ManageBloodShipmentAPI();
